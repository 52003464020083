import Vue from 'vue'
import cfg from '../../../cfg.json'
import LogRocket from 'logrocket'

if (cfg.logRocket && process.env.NODE_ENV === 'production') {
  LogRocket.init(cfg.logRocket)
  LogRocket.log('useragent', window.navigator.userAgent)

  Vue.prototype.$logRocket = LogRocket
} else {
  Vue.prototype.$logRocket = { log: () => {}, identify: () => {} }
}
