import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import de from 'vuetify/lib/locale/de'
import fr from 'vuetify/lib/locale/fr'

Vue.use(Vuetify)

const locale = Vue.$cfg.lang || 'de'

export default new Vuetify({
  theme: Vue.$cfg.theme,
  lang: {
    locales: { de, fr },
    current: locale
  }
})
