<template>
  <home v-if="$root.ready && mode === 'app'"/>

  <print v-else-if="$root.ready && mode === 'print' && $root.isAdmin"/>

  <sign-up-in v-else-if="$root.userId === false"/>

  <v-app v-else>
    <v-overlay :value="true" opacity="0">
      <v-progress-circular
        color="primary"
        indeterminate
        size="50"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
    Home: () => import('@/views/Home'),
    Print: () => import('@/views/Print'),
    SignUpIn: () => import('@/views/SignUpIn'),
    ImgCmp: () => import('@/components/base/ImgCmp')
  },
  data () {
    return {
      mode: null
    }
  },
  created () {
    this.mode = window.location.pathname.substr(1) || 'app'
  }
}
</script>
