import './plugins/cfg'
import Vue from 'vue'
import App from './App.vue'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import './plugins/firebase'
import './plugins/day'
import './plugins/imgix'
import './plugins/linkify'
import './plugins/logrocket'
import './plugins/reactiveprovide'
import './plugins/tel'
import './plugins/vuephotogallery'
import './plugins/youtube'
import './registerServiceWorker'
import './assets/styles.css'

Vue.config.productionTip = false

new Vue({
  i18n,
  vuetify,

  data () {
    return {
      user: null, // null if not initialized, false if not authenticated
      userClaims: null, // user custom claims
      notifications: [], // user notifications (unread)
      notificationId: null, // from push from SW, notification Id to open
      views: {}, // core.views
      tags: {}, // core.tags
      cals: {}, // core.cals
      users: {}, // core.users
      groups: {}, // groups (public only (core.groups), or all (groups) if admin)
      displayUserId: null,
      snack: null,
      showUpdateSnack: false,
      updating: false,
    }
  },

  computed: {
    userId () {
      return this.user
        ? this.user.ok
          ? this.user.uid // true if auth and ok (signup done)
          : false // false if auth but signUp not done yet
        : this.user // null if not initialized, false if not authenticated
    },
    isAdmin () {
      return this.userClaims?.role === 'admin' || null
    },
    isModerator () {
      return this.userId && Object.entries(this.views).find(([id, view]) => view.moderators?.includes(this.userId))
    },
    userGroupIds () {
      return this.userClaims?.groupIds || null
    },
    ready () {
      return this.userId && this.userClaims && Object.keys(this.users).length > 0
    },
    groupOpts () {
      if (!this.groups || Object.keys(this.groups).length === 0) return null
      return Object.keys(this.groups).map(groupId => ({
        text: this.groups[groupId].name,
        value: groupId
      })).sort((a, b) => a.text < b.text ? -1 : 1)
    }
  },

  methods: {
    getUserName (userId) {
      if (this.userId === userId) return 'Ich'
      return this.users[userId]?.name || 'Unbekannt'
    },
    loadUser (user) {
      const userAuth = { uid: user.uid, email: user.email, phoneNumber: user.phoneNumber, provider: user.providerData[0].providerId }

      this.unsubUser = this.$fb.db.doc('users/' + user.uid)
        .onSnapshot(doc => {
          const userData = doc.exists ? doc.data() : {}
          this.user = Object.assign({}, userAuth, userData)

          // force a refresh of the ID token, which will pick up new claims
          user.getIdTokenResult(true).then(idTokenResult => {
            this.userClaims = idTokenResult.claims
          })

          this.unsubNotifications = this.$fb.db.collection('users/' + user.uid + '/notifications')
            .where('read', '==', false)
            .orderBy('ts', 'desc')
            .limit(10)
            .onSnapshot(snap => {
              const notifications = []
              snap.forEach(doc => {
                notifications.push({
                  id: doc.id,
                  ...doc.data()
                })
              })
              this.notifications = notifications
            })

          if (!this.user.active) {
            this.$fb.db.doc('users/' + user.uid).update({
              active: true
            })
          }

          this.saveStats('app-start')

          this.$logRocket.identify(user.uid, this.user)
        })
    },
    loadData () {
      this.$fb.db.doc('settings/core').get()
        .then(doc => {
          const core = doc.data()
          this.views = core.views
          this.tags = core.tags
          this.cals = core.cals
          this.users = core.users
          if (!this.isAdmin) {
            this.groups = core.groups
          }
        })
      if (this.isAdmin) {
        this.loadAdminData()
      }
    },
    reloadData () {
      setTimeout(() => { // wait for db triggers to run
        this.loadData()
      }, 3000)
    },
    loadAdminData () {
      if (this.$cfg.groups !== false) {
        this.$fb.db.collection('groups')
          .get()
          .then(snap => {
            const groups = {}
            snap.forEach(doc => {
              const group = doc.data()
              if (!group._deleted) {
                groups[doc.id] = group
              }
            })
            this.groups = groups
          })
        }
    },
    signOut () {
      this.unloadUser()
      this.$nextTick(() => { // wait for views to get distroyed and unsubscribe fb listeners
        this.$fb.auth.signOut()
      })
    },
    unloadUser () {
      if (this.unsubUser) {
        this.unsubUser()
      }
      if (this.unsubNotifications) {
        this.unsubNotifications()
      }
      this.user = false
      this.userClaims = null
    },
    saveStats (type) {
      this.$fb.db.collection('stats').add({
        type,
        userId: this.userId,
        userEmail: this.user.email || '',
        platform: 'web',
        ts: this.$fb.fb.firestore.FieldValue.serverTimestamp()
      })
    },
    updateApp () {
      this.updating = true
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) {
        this.showUpdateSnack = false
        return
      }
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  },

  watch: {
    isAdmin (isAdmin) {
      if (isAdmin) {
        this.loadAdminData()
      }
    }
  },

  created () {
    window.document.title = this.$cfg.appTitle

    // auth
    this.$fb.auth.onAuthStateChanged(user => {
      if (user) {
        this.loadUser(user)
        this.loadData()
      } else {
        this.unloadUser()
      }
    })

    // beforeinstallprompt
    window.addEventListener('beforeinstallprompt', event => {
      window.beforeinstallpromptEvent = event
    })

    // serviceworker - updates
    document.addEventListener('swUpdated', event => {
      this.registration = event.detail
      this.showUpdateSnack = true
      if (this.$cfg.autoUpdate) {
        this.updateApp()
      }
    }, { once: true })

    if (navigator.serviceWorker !== undefined) { // may not be available in private mode or local
      let reloading = false
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (reloading) return
        reloading = true
        window.location.reload()
      })
    }

    // serviceworker - push notifications - opening with url param
    const urlParams = new URLSearchParams(window.location.search)
    this.notificationId = urlParams.get('notificationId')
  },

  render: h => h(App)
}).$mount('#app')
